<template>
  <Layout class="GameLibrary">
    <BHeader>
      <el-form
        ref="form"
        :inline="true"
        :model="formData"
      >
        <el-form-item>
          <el-input
            v-model="formData.orderId"
            placeholder="订单编号"
            :maxlength="19"
            @input.native="inputhandle"
          />
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="formData.uid"
            placeholder="UID"
            :maxlength="19"
            @input.native="inputhandle"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearch"
          >
            搜索
          </el-button>
        </el-form-item>
      </el-form>
    </BHeader>
    <el-table
      v-loading="listInfo.loading"
      :data="listInfo.list"
      stripe
      style="width: 100%"
    >
      <el-table-column
        label="订单编号"
        prop="orderId"
      />
      <el-table-column
        label="商品名称"
        prop="goodsName"
      />
      <el-table-column
        label="用户ID"
        prop="userId"
      />
      <el-table-column
        label="消耗积分"
        prop="exchangePoints"
      />
      <el-table-column
        label="兑换时间"
        prop="exchangeTime"
      />
      <el-table-column
        label="CDK"
        prop="cdkInfo"
      />
      <el-table-column
        label="订单状态"
        width="100px"
      >
        <template #default="{row}">
          <span v-if="row.orderStatus===1">待处理</span>
          <span v-if="row.orderStatus===2">已发送</span>
          <span v-if="row.orderStatus===3">发送失败</span>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :total="listInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="querySpiderGameList"
    />
  </Layout>
</template>

<script>
import {
  getPointsMallOrderList
} from '@/api/checksetting.js'

export default {
  data () {
    return {
      headImageVerticalInfo: {
        data: {},
        url: '',
        isEdit: false,
        loading: false
      },
      statisticsLoading: false,
      statisticsInfo: {},
      statusList: [
        { name: '未编辑', value: 1 },
        { name: '已编辑', value: 2 }
      ],
      statusMap: {
        1: '未编辑',
        2: '已编辑'
      },
      formData: {
        pageNum: 1,
        pageSize: 20
      },
      listInfo: {
        list: [],
        total: 0,
        loading: false
      },
      content: '',
      arrimg: [],
      nationCode: '',
      userName: '',
      newGameVisible: false,
      newGameLoading: false,
      newGameForm: {
        platform: 'steam',
        sourceId: ''
      }
    }
  },
  mounted () {
    this.handleSearch()
  },
  methods: {
    inputhandle ({ target }) {
      this.verificationVal = target.value = target.value.replace(/[^0-9]/g, '')
    },
    handleSearch () {
      this.formData.pageNum = 1
      this.querySpiderGameList()
    },
    querySpiderGameList () {
      this.listInfo.loading = true
      getPointsMallOrderList(this.formData)
        .then(res => {
          if (res.code === 200) {
            this.listInfo.list = res.data.list || []
            this.listInfo.total = res.data.total || 0
          }
        })
        .finally(() => {
          this.listInfo.loading = false
        })
    }

  }
}
</script>

<style scoped lang="less">
  .GameLibrary {
    .overview {
      display: flex;
      // padding: 0 0 20px 0;
      min-width: 880px;
      margin-bottom: 10px;
      .items {
        width: 210px;
        height: 100px;
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, .12);
        border-radius: 4px;
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .num {
          font-size: 26px;
          margin-bottom: 10px;
          .unit {
            font-size: 14px;
          }
        }
        .name {
          font-size: 12px;
        }
      }
    }
  }
  .left20{
    margin-left: 40px;
  }
</style>
